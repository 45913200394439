/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import SelectInput from 'components/input/SelectInput';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import TextIconInput from 'components/input/TextIconInput';
import { csuCountry, yearOptions } from 'components/helper/helper';
import collegeServices from 'services/collegeServices';
import { debounce, defer } from 'lodash';
import LoadingContent from 'components/LoadingContent';
import TextInput from 'components/input/textInput';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import { canadaProvince, usaState } from 'components/helper/stateAndCounty';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  formik: any;
  indexText: string;
  item: any;
  index: number;
}

const EducationConfig = ({ formik, indexText, item, index }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');

  React.useEffect(() => {
    if (item?.school?.name) {
      setSelectedEducation(item?.school);
    }
  }, []);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [selectedEducation, setSelectedEducation] = React.useState<any>();
  const [collegeData, setCollegeData] = React.useState<any>();
  const handleGetCollege = async (keyword: string) => {
    setIsLoading(true);
    const res: any = await collegeServices.getCollege({ keyword: keyword.trim(), limit_item: 20 });
    const data = res.map((item: any) => ({
      ...item,
      id: item.id,
      label: item.name,
      value: item.name,
    }));
    setCollegeData(data);
    setIsLoading(false);
    setOpenMenu(true);
  };

  const handleSearchCollege = debounce((keyword: string) => {
    defer(() => {
      handleGetCollege(keyword);
    });
  }, 300);

  const NAME = `parent_graduation_college_array[${index}]`;

  return (
    <Card className="w-100 my-2 px-0" key={indexText}>
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',
        }}
      >
        <div className="w-100 d-flex justify-content-between">
          <span className="p-1">{item?.school?.name ?? ''}</span>
          <strong className="text-field-light">{indexText}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row className="p-3">
            <TextIconInput
              label="Search for your parent graduation College / University"
              logo="span-cm-logo"
              icon={<SearchIcon />}
              mdCol="12"
              onChange={(e: any) => {
                setSearchInput(e.target.value);
                handleSearchCollege(e.target.value);
              }}
              values={searchInput}
            />
            {isLoading && <LoadingContent />}
            {collegeData !== undefined &&
              !collegeData?.length &&
              !isLoading &&
              !item?.school?.name && <div className="text-center">Not found college</div>}
            {openMenu &&
              (collegeData || []).map(
                (
                  college: {
                    label:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                    address_line_1:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                  },
                  index: React.Key | null | undefined
                ) =>
                  college?.label ? (
                    <Card className="border rounded my-1 pt-2 pb-1" key={index}>
                      <div className="d-flex align-items-end position-relative">
                        <div className="mx-3 pb-2 ">{college.label}</div>
                        <div className="text-field-light sized70 pb-2">
                          {college.address_line_1}
                        </div>
                        <span className="position-absolute h-100 d-flex align-items-center end-0">
                          <AddIcon
                            fontSize="small"
                            className="bg-app text-primary rounded-circle mx-3 pointer"
                            onClick={() => {
                              setSelectedEducation(college);
                              formik.setFieldValue(NAME + 'school', college);
                              setOpenMenu(false);
                              setSearchInput('');
                            }}
                          />
                        </span>
                      </div>
                    </Card>
                  ) : null
              )}

            <div className="my-2 text-field-light">Parent Graduation College</div>
            {selectedEducation?.id ? (
              <div className="d-flex align-items-end position-relative">
                <div className="mx-3 pb-2 fw-bold">{selectedEducation?.name}</div>
                <div className="text-field-light sized70  pb-2">
                  {selectedEducation?.address_line_1}
                </div>
                <span className="position-absolute h-100 d-flex align-items-center end-0">
                  <RemoveIcon
                    fontSize="small"
                    className="bg-light text-dart rounded-circle mx-4 pointer"
                    onClick={() => {
                      setSelectedEducation(undefined);
                      formik.setFieldValue(NAME + 'school', null);
                    }}
                  />
                </span>
              </div>
            ) : (collegeData !== undefined && !collegeData?.length) || selectedEducation?.name ? (
              <Row className="bg-light rounded p-3">
                <TextInput
                  name={NAME + 'school.name'}
                  values={item?.school?.name}
                  onChange={formik.handleChange}
                  label="Name of college/university"
                  countText={60}
                />
                <AutocompleteInput
                  name={NAME + 'school.country'}
                  form={formik}
                  values={item?.school?.country}
                  label="Country/Region/Territory*"
                  option={csuCountry}
                />
                <TextInput
                  mdCol={12}
                  name={NAME + 'school.address_line_1'}
                  values={item?.school?.address_line_1}
                  onChange={formik.handleChange}
                  label="Address Line 1"
                  countText={60}
                />
                <TextInput
                  mdCol={12}
                  name={NAME + 'school.address_line_2'}
                  values={item?.school?.address_line_2}
                  onChange={formik.handleChange}
                  label="Address Line 2"
                  countText={60}
                />
                <TextInput
                  mdCol={12}
                  name={NAME + 'school.address_line_3'}
                  values={item?.school?.address_line_3}
                  onChange={formik.handleChange}
                  label="Address Line 3"
                  countText={60}
                />
                <TextInput
                  name={NAME + 'school.city'}
                  values={item?.school?.city}
                  onChange={formik.handleChange}
                  label="City"
                  countText={30}
                />
                <AutocompleteInput
                  option={usaState}
                  form={formik}
                  hidden={item?.school?.country !== 'United States of America'}
                  label="State"
                  name={NAME + 'school.state'}
                  values={item?.school?.state}
                />
                <AutocompleteInput
                  form={formik}
                  option={canadaProvince}
                  hidden={item?.school?.country !== 'Canada'}
                  label="Province"
                  name={NAME + 'school.province'}
                  values={item?.school?.province}
                />
                <TextInput
                  hidden={
                    item?.school?.country === 'Canada' ||
                    item?.school?.country === 'United States of America'
                  }
                  name={NAME + 'school.state_or_province'}
                  values={item?.school?.state_or_province}
                  onChange={formik.handleChange}
                  label="State or Province"
                  countText={30}
                />
                <TextInput
                  name={NAME + 'school.zip_code'}
                  values={item?.school?.zip_code}
                  onChange={formik.handleChange}
                  label="Zip Code"
                  countText={10}
                />
              </Row>
            ) : null}
            <SelectInput
              logo="span-cm-logo"
              form={formik}
              option={[
                { value: 'No degree' },
                { value: '1' },
                { value: '2' },
                { value: '3' },
                { value: '4' },
                { value: '5' },
              ]}
              label={'Degrees Number'}
              name={NAME + 'degreesNumber'}
              values={item?.degreesNumber}
              onChange={formik.handleChange}
            />
            <div />

            {parseInt(item?.degreesNumber ?? '0') >= 1 && (
              <>
                <SelectInput
                  logo="span-cm-logo"
                  option={yearOptions}
                  name={NAME + `graduationYear1`}
                  values={item?.graduationYear1}
                  onChange={formik.handleChange}
                  label={`Graduation Year (1)`}
                />
                <SelectInput
                  logo="span-cm-logo"
                  form={formik}
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (1)'}
                  name={NAME + 'degreesReceived1'}
                  values={item?.degreesReceived1}
                  onChange={formik.handleChange}
                />
              </>
            )}
            {parseInt(item?.degreesNumber) >= 2 && (
              <>
                <SelectInput
                  logo="span-cm-logo"
                  option={yearOptions}
                  name={NAME + `graduationYear2`}
                  values={item?.graduationYear2}
                  onChange={formik.handleChange}
                  label={`Graduation Year (2)`}
                />
                <SelectInput
                  logo="span-cm-logo"
                  form={formik}
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (2)'}
                  name={NAME + 'degreesReceived2'}
                  values={item?.degreesReceived2}
                  onChange={formik.handleChange}
                />
              </>
            )}
            {parseInt(item?.degreesNumber) >= 3 && (
              <>
                <SelectInput
                  option={yearOptions}
                  logo="span-cm-logo"
                  name={NAME + `graduationYear3`}
                  values={item?.graduationYear3}
                  onChange={formik.handleChange}
                  label={`Graduation Year (3)`}
                />
                <SelectInput
                  form={formik}
                  logo="span-cm-logo"
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (3)'}
                  name={NAME + 'degreesReceived3'}
                  values={item?.degreesReceived3}
                  onChange={formik.handleChange}
                />
              </>
            )}
            {parseInt(item?.degreesNumber) >= 4 && (
              <>
                <SelectInput
                  logo="span-cm-logo"
                  option={yearOptions}
                  name={NAME + `graduationYear4`}
                  values={item?.graduationYear4}
                  onChange={formik.handleChange}
                  label={`Graduation Year (4)`}
                />
                <SelectInput
                  form={formik}
                  logo="span-cm-logo"
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (4)'}
                  name={NAME + 'degreesReceived4'}
                  values={item?.degreesReceived4}
                  onChange={formik.handleChange}
                />
              </>
            )}
            {parseInt(item?.degreesNumber) >= 5 && (
              <>
                <SelectInput
                  logo="span-cm-logo"
                  option={yearOptions}
                  name={NAME + `graduationYear5`}
                  values={item?.graduationYear5}
                  onChange={formik.handleChange}
                  label={`Graduation Year (5)`}
                />
                <SelectInput
                  form={formik}
                  logo="span-cm-logo"
                  option={[
                    { value: `Associate's (AA, AS)` },
                    { value: `Bachelor's (BA, BS)` },
                    { value: `Master's (MA, MS)` },
                    { value: `Business (MBA, MAcc)` },
                    { value: `Law (JD, LLM)` },
                    { value: `Medicine (MD, DO, DVM, DDS)` },
                    { value: `Doctorate (PhD, EdD, etc)` },
                    { value: 'Other' },
                  ]}
                  label={'Degree Received (5)'}
                  name={NAME + 'degreesReceived5'}
                  values={item?.degreesReceived5}
                  onChange={formik.handleChange}
                />
              </>
            )}
            <Col xs={6} md={9} />

            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  setExpanded(false);
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default EducationConfig;
