import CheckboxInput from 'components/input/CheckboxInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import TextAreaInput from 'components/input/TextAreaInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import TextInput from 'components/input/textInput';
import React from 'react';
import { Row } from 'react-bootstrap';
interface Props {
  formik: any;
}

const ReleaseStatement = ({ formik }: Props) => {
  return (
    <Row className="col-12">
      <div className="text-field-mid fs-3 mb-4" id="CSU">
        Release Statement
      </div>
      <div className="span-csu-logo fs-5 p-1">
        CERTIFICATION - to be read and authorized by all applicants to certify the accuracy of the
        information provided.
      </div>
      <div className="mt-2 span-csu-logo text-field-light">
        I certify under penalty of perjury under the laws of the State of California that I have
        provided complete and accurate responses to all the items on this application. I further
        certify that all official documents submitted in support of this application are authentic
        and unaltered records that pertain to me. I authorize the California State University to
        release any information submitted by me in this application for admission and any
        application for financial aid to any person, firm, corporation, association, or government
        agency to verify or explain the information I have provided or to obtain other information
        necessary for my application for admission and any application for administration of
        financial aid and in connection with any perjury proceedings. I authorize the California
        State University system to release any submitted test results to all campuses to which I
        submit an application. My certification verifies the accuracy and completeness of the
        information provided. I understand that any misrepresentation or omission may be cause for
        denial or cancellation of admission, transfer credit, or enrollment. I certify that so long
        as I am a student at this institution, I will advise the residence clerk if there is a
        change in any of the facts affecting my residence.
      </div>
      <CheckboxInput
        setValue={(e) => formik.setFieldValue('csu_info.is_certify', e)}
        mdCol={'12'}
        label={
          'Your certification of this statement serves the same purpose as a legal signature, and is binding.'
        }
        name={'csu_info.is_certify'}
        values={formik.values.csu_info?.is_certify}
        onChange={formik.handleChange}
      />
      <div className="my-3"></div>
      <div className="span-csu-logo fs-5 p-1">CAASPP Results Release Statement</div>
      <div className="text-field-light span-csu-logo">
        I hereby authorize the California Department of Education to release my California
        Assessment of Student Performance and Progress (CAASPP) score report to the California State
        University for the purpose of credit, placement, determination of readiness for
        college-level coursework, or admission.
      </div>
      <GroupRadioButton
        mdCol={12}
        name="csu_info.hereby_authorize_CD_release_CAASPP"
        values={formik.values.csu_info?.hereby_authorize_CD_release_CAASPP}
        onChange={formik.handleChange}
        options={[
          'I completed the CAASPP assessment and agree to release my results',
          'I completed the CAASPP assessment and do not agree to release my results',
          'I did not attend High School in California, or did not complete the CAASPP assessment',
        ]}
      />
      <div className="my-4 fs-5 p-1">Release of Contact Information</div>
      <div className="text-field-light">
        I authorize the California State University to release my contact information to one or more
        CSU campuses and/or affiliates that may wish to contact me before and/or after I have
        submitted my application to the university.
      </div>
      <CheckboxInput
        setValue={(e) =>
          formik.setFieldValue('csu_info.is_authorize_CSU_release_contact_information', e)
        }
        mdCol={'12'}
        logo="span-csu-logo"
        label="Check box to Acknowledge"
        name={'csu_info.is_authorize_CSU_release_contact_information'}
        values={formik.values.csu_info?.is_authorize_CSU_release_contact_information}
        onChange={formik.handleChange}
      />
      <div className="my-4 fs-5 p-1">Additional Information Release</div>
      <div className="text-field-light">
        I authorize the California State University to release to my school or college
        counselor/counseling office (or sponsoring agency) information regarding my application,
        including test scores, transcripts and other supporting documents, as they relate to my
        admission and scholarship status. If you do not check the box, CSU may not inform your
        school or counselor whether you have applied or been admitted.
      </div>
      <CheckboxInput
        setValue={(e) =>
          formik.setFieldValue('csu_info.is_authorize_CSU_release_my_application', e)
        }
        mdCol={'12'}
        logo="span-csu-logo"
        label="Check box to Acknowledge"
        name={'csu_info.is_authorize_CSU_release_my_application'}
        values={formik.values.csu_info?.is_authorize_CSU_release_my_application}
        onChange={formik.handleChange}
      />
      <div className="my-3"></div>
      <div className="fs-5 p-1">Campus Housing</div>
      <YesNoSwitch
        className="mb-3"
        logo="span-csu-logo"
        name={'csu_info.is_interested_on_campus_space_available'}
        values={formik.values.csu_info?.is_interested_on_campus_space_available}
        onChange={formik.handleChange}
        label="Are you interested in on campus housing should space be available? Note: housing availability is subject to your submission of a separate campus housing application and availability."
      />
      <div className="text-field-mid fs-5 p-1 my-3">Applicant Information</div>
      <YesNoSwitch
        className="mb-3"
        name={'csu_info.in_foster_care'}
        logo="span-csu-logo"
        values={formik.values.csu_info?.in_foster_care}
        onChange={formik.handleChange}
        label="At any time since you turned age 13, were both your parents deceased, were you in foster care or were you a dependent or ward of the court?"
      />
      <YesNoSwitch
        className="mb-3"
        name={'csu_info.is_emancipated_minor'}
        logo="span-csu-logo"
        values={formik.values.csu_info?.is_emancipated_minor}
        onChange={formik.handleChange}
        label="As determined by a court in your state of legal residence, are you or were you an emancipated minor?"
      />
      <YesNoSwitch
        className="mb-3"
        name={'csu_info.is_homeless_liaison_determine'}
        logo="span-csu-logo"
        values={formik.values.csu_info?.is_homeless_liaison_determine}
        onChange={formik.handleChange}
        label="At any time on or after July 1, 2024, did your high school or school district homeless liaison determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?"
      />
      <YesNoSwitch
        className="mb-3"
        name={'csu_info.is_director_or_transitional_determine'}
        logo="span-csu-logo"
        values={formik.values.csu_info?.is_director_or_transitional_determine}
        onChange={formik.handleChange}
        label="At any time on or after July 1, 2024, did the director of an emergency shelter or transitional housing program funded by the U.S. Department of Housing and Urban Development determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?"
      />
      <YesNoSwitch
        className="mb-3"
        name={'csu_info.is_director_of_runaway_or_homeless_youth_basic'}
        logo="span-csu-logo"
        values={formik.values.csu_info?.is_director_of_runaway_or_homeless_youth_basic}
        onChange={formik.handleChange}
        label="At any time on or after July 1, 2024, did the director of a runaway or homeless youth basic center or transitional living program determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?"
      />
      <div className="fs-5 p-1 mt-4">Statewide Student ID</div>
      <YesNoSwitch
        className="mb-3"
        label="Have you ever attended a California public school?"
        logo="span-uc-logo"
        onChange={formik.handleChange}
        values={formik.values.csu_info?.attended_California_public_school}
        name="csu_info.attended_California_public_school"
      />

      {formik.values.csu_info?.attended_California_public_school === 'true' && (
        <>
          <div className="text-dart mt-1">
            If you know your California State Student ID (CA SSID), please enter it below. Your
            10-digit CA SSID can be found on your high school transcripts. If you need help finding
            it, please contact your high school counselor.
          </div>
          <div className="text-field-light span-csu-logo">
            A Statewide Student ID (SSID) is a unique, 10-digit number assigned to you if you
            attended a public California K - 12 school. The number is located on your California
            high school transcript. If you did not attend a public high school in California, you
            may leave the SSID blank.
          </div>
          <div className="text-danger">
            Do NOT enter your Social Security Number into this field.
          </div>
          <TextInput
            logo="span-csu-logo"
            onChange={formik.handleChange}
            values={formik.values.csu_info?.ssid}
            name="csu_info.ssid"
            label="California Statewide Student Identifier (SSID)"
          />

          <CheckboxInput
            logo="span-uc-logo"
            onChange={formik.handleChange}
            values={formik.values.csu_info?.authorize_release_CASSID_for_tracking_UC_application}
            name="csu_info.authorize_release_CASSID_for_tracking_UC_application"
            label="I authorize the release of my CA SSID Number and application status to California Guidance College Initiative (CCGI) for the purpose of tracking my UC application. I understand that the information will only be shared if my school has provided my transcript to CCGI."
          />
        </>
      )}
      <div className="mt-3 fs-5 p-1">Academic Standing</div>
      <YesNoSwitch
        className="mb-3"
        logo="span-csu-logo"
        label="Are you in good standing with your last school attended?"
        name={'csu_info.is_in_good_standing'}
        values={formik.values.csu_info?.is_in_good_standing}
        onChange={formik.handleChange}
      />
      <div className="fs-5 p-1 mt-3">Academic Infractions and Conduct</div>
      <YesNoSwitch
        className="mb-3"
        logo="span-csu-logo"
        label="Have you ever experienced academic probation or academic disqualification by any college or university?"
        name={'csu_info.is_disciplined_for_academic_performance'}
        values={formik.values.csu_info?.is_disciplined_for_academic_performance}
        onChange={formik.handleChange}
      />
      {/* // removed 2024-10-03 - usesnap 1744
      <YesNoSwitch
        className="mb-3"
        label="Have you been academically disqualified at any college or university?"
        name={'csu_info.is_academically_disqualified'}
        values={formik.values.csu_info?.is_academically_disqualified}
        onChange={formik.handleChange}
      /> */}
      <label className="px-1 label-14px d-block mb-2 span-csu-logo ">Have you ever experienced <u>discipline</u> for student conduct violations (e.g. suspension, expulsion, dismissal etc.) by any college or university?</label>
      <YesNoSwitch
        className="mb-3"
        name={'csu_info.is_disciplined_for_student_conduct_violations'}
        values={formik.values.csu_info?.is_disciplined_for_student_conduct_violations}
        onChange={formik.handleChange}
      />
      {(formik.values.csu_info?.is_disciplined_for_academic_performance === 'true' ||
        formik.values.csu_info?.is_disciplined_for_student_conduct_violations === 'true') && (
        <TextAreaInput
          mdCol='12'
          logo="span-csu-logo"
          name={'csu_info.explain_for_each_violation'}
          values={formik.values.csu_info?.explain_for_each_violation}
          onChange={formik.handleChange}
          label="If you answered yes to any of the questions above, please explain include the college/university."
          countWord={1500}
        />
      )}
      <div className="fs-5 p-1">Teacher or Other Education Credential Information</div>
      <SelectInput
        logo="span-csu-logo"
        option={[
          'Select',
          'Not Interested in a credential program',
          'Planning to apply to a credential program at a later time',
          'Planning to apply to a credential program for this term',
        ]}
        name={'csu_info.interest_in_teacher_or_educational_credential'}
        values={formik.values.csu_info?.interest_in_teacher_or_educational_credential}
        onChange={formik.handleChange}
        label="Select your interest in obtaining your teacher or other educational credential."
      />
      <div className="fs-5 p-1">CalFresh</div>
      <YesNoSwitch
        className="mb-3"
        logo="span-csu-logo"
        label="Are you interested in checking your eligibility for monthly food assistance to be used towards groceries through the CalFresh Program?"
        name={'csu_info.is_interested_eligibility_assistance_CalFresh'}
        values={formik.values.csu_info?.is_interested_eligibility_assistance_CalFresh}
        onChange={formik.handleChange}
      />
      <div className="fs-5 p-1">CA Promise</div>
      <YesNoSwitch
        className="mb-3"
        logo="span-csu-logo"
        label="Are you interested in participating in the CA Promise?"
        name={'csu_info.is_interested_participating_CA_Promise'}
        values={formik.values.csu_info?.is_interested_participating_CA_Promise}
        onChange={formik.handleChange}
      />
      <div className="fs-5 p-1">RN License</div>
      <YesNoSwitch
        className="mb-3"
        logo="span-csu-logo"
        values={formik.values.csu_info?.have_rn_license}
        name="csu_info.have_rn_license"
        onChange={formik.handleChange}
        label="*Do you have an RN (registered nurse) license or expect to achieve a RN license?"
      />
      <div className="fs-5 p-1">How did you Hear About Us?</div>
      <SelectInput
        logo="span-csu-logo"
        option={[
          'Counselor/Advisor',
          'CSU Campus Tour/Visit',
          'CSU How to Get to College Website',
          'CSU Outreach Program',
          'CSU Super Sunday/Super Saturday',
          'Es el Momento Education Fair',
          'Faculty Member/Teacher',
          'Friend',
          'Journey to Success Education Fair',
          'Link from a CSU Campus',
          'Link from another site (not a search engine)',
          'Magazine',
          'Newspaper',
          'Online Ad',
          'Parent Institute for Quality Education (PIQE)',
          'Parent/Family Member',
          'Radio',
          'Search Engine',
          'Television',
          'Other College Fair',
          'Other',
        ]}
        name={'csu_info.how_hear_about_CalState'}
        values={formik.values.csu_info?.how_hear_about_CalState}
        onChange={formik.handleChange}
        label="How did you hear about CalState.edu/apply?"
      />
      <TextInput
        logo="span-csu-logo"
        hidden={formik.values.csu_info?.have_rn_license !== 'true'}
        values={formik.values.csu_info?.rn_license}
        name="csu_info.rn_license"
        onChange={formik.handleChange}
        type="number"
        label="RN License"
        countText={10}
        feedback="Enter '0' if you have not yet received a license number."
      />

      <div className="fs-5 p-1  mt-3" id="UC">
        Community principles
      </div>
      <div className="text-field-light p-1">
        We are a community of people from diverse backgrounds, with diverse identities, abilities,
        and needs. Everything we do is supported and enriched by an atmosphere of positive
        engagement and a spirit of cooperation and inquiry.
        <br />
        Each UC campus has committed to Principles of Community that reaffirm each individual’s
        right to dignity and civil discourse. We ask you to agree that, if you become part of the UC
        community, you will seek to maintain a climate of civility and mutual respect.
        <br />
        The following principles serve as a guide and reflect our passion for critical inquiry and
        our deep commitment to contributing to a better world:
        <br />
        — We place honesty and integrity in our teaching, learning, research and administration at
        the highest level.
        <br />
        — We respect the differences as well as the commonalities that bring us together.
        <br />
        — We reject acts of discrimination based on race, ethnicity, sex, gender identity, age,
        disability, sexual orientation, religion, and political beliefs.
        <br />— We are committed to ensuring freedom of expression. We celebrate the expression of
        individuality and diversity within the bounds of courtesy, sensitivity, and respect.
      </div>

      <CheckboxInput
        label="I acknowledge, value, and agree with these guiding principles.*"
        logo="span-uc-logo"
        className="mb-2"
        onChange={formik.handleChange}
        values={formik.values.csu_info?.agree_with_guiding_principles}
        name="csu_info.agree_with_guiding_principles"
        setValue={(e) => formik.setFieldValue('csu_info.agree_with_guiding_principles', e)}
      />
    </Row>
  );
};

export default ReleaseStatement;
